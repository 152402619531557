import Vue from "vue";
import Router from "vue-router";
import EmptyRouterView from "./components/EmptyRouterView"
//import LocationEdit from "./components/LocationEdit"
import Home from './views/Home.vue';
import Login from './views/Login.vue';
//import Register from './views/Register.vue';

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: [{
		path: "/",
		redirect: { name: "home" }
	},
	{
		path: "/location",
		component: EmptyRouterView,
		children: [{
			name: 'location',
			path: '',
			component: () =>
				import("./components/LocationList")
		}, {
			name: "location-add",
			path: "add",
			components: {
				default: () =>
					import("./components/LocationList"),
				dialog: () =>
					import("./components/LocationAdd")
			}
		}, {
			name: 'location-edit',
			path: ':locationId/edit',
			components: {
				default: () =>
					import("./components/LocationList"),
				dialog: () =>
					import("./components/LocationEdit")
			},
			props: { default: false, dialog: true }
		}, {
			name: 'location-delete',
			path: ':locationId/delete',
			components: {
				default: () =>
					import("./components/LocationList"),
				dialog: () =>
					import("./components/LocationDelete")
			},
			props: { default: false, dialog: true }
		}]
	},
	{
		path: "/box",
		component: EmptyRouterView,
		children: [{
			name: 'box',
			path: '',
			//props: route => ({ query: route.query.location }),
			props: route => ({ ...route.query, ...route.params }),
			component: () => import("./components/BoxList"),
		}, {
			name: "box-add",
			path: "add",
			component: () => import("./components/BoxAdd")
		}, {
			name: 'box-edit',
			path: ':boxId/edit',
			components: {
				default: () => import("./components/BoxList"),
				dialog: () => import("./components/BoxEdit")
			},
			props: { default: false, dialog: true }
		}]
	},
	{
		path: "/item",
		name: "item-list",
		props: route => ({ ...route.query, ...route.params }),
		component: () => import("./components/ItemList")
	},
	{
		path: "/item/add",
		name: "item-add",
		component: () => import("./components/ItemAdd")
	},
	{
		path: "/item/:id",
		name: "item-view",
		props: true,
		component: () => import("./components/ItemView")
	},
	{
		name: "item-edit",
		path: "/item/:id/edit",
		props: true,
		component: () => import("./components/ItemEdit")
	},
	{
		path: "/user",
		name: "user-list",
		component: () => import("./components/UserList")
	},
	{
		path: "/user/add",
		name: "user-add",
		component: () => import("./components/UserAdd")
	},
	{
		path: "/user/:id",
		name: "user-view",
		props: true,
		component: () => import("./components/UserView")
	},
	{
		path: "/user/:id/edit",
		name: "user-edit",
		props: true,
		component: () => import("./components/UserEdit")
	},
	{
		path: '/home',
		name: 'home',
		component: Home
	},
	{
		path: '/login',
		name: 'login',
		props: route => ({ redirect: route.query.redirect }),
		component: Login
	},
	/*
	{
		path: '/register',
		name: 'register',
		component: Register
	},
	*/
	{
		path: '/profile',
		name: 'profile',
		// lazy-loaded
		component: () =>
			import('./views/Profile.vue')
	},
	{
		path: '/help',
		name: 'help',
		// lazy-loaded
		component: () =>
			import('./views/Help.vue')
	},
	{
		path: "/:itemId",
		name: "item-redirect",
		//redirect: { name: 'item-view', params: {id: itemId}}
		redirect: { path: 'item/:itemId'}
		
	}
	]
});

router.beforeEach((to, from, next) => {
	const publicPages = ['/login', '/register', '/home', '/help'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user');

	// trying to access a restricted page + not logged in
	// redirect to login page
	if (authRequired && !loggedIn) {
		//next({ name: 'login', params: { redirect: to.path } });
		next(`/login?redirect=${to.path}`)
	} else {
		next();
	}
});

export default router;