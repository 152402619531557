<template>
  <div align="center">
    <!--<v-dialog  v-model="dialog" persistent max-width="600px">-->
    <v-card max-width="600px" @keyup.enter="handleLogin">
      <v-card-title>
        <span class="headline">Login</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="user.username"
              :counter="10"
              :rules="[rules.required]"
              label="Name"
              required
            ></v-text-field>

            <v-text-field
              :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPw ? 'text' : 'password'"
              v-model="user.password"
              name="input-10-2"
              label="Password"
              class="input-group--focused"
              @click:append="showPw = !showPw"
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" color="accent" @click="handleLogin"> Login </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import User from "../models/user";
import { EventBus } from "@/event-bus.js";

export default {
  name: "App-Login",
  props: {
    redirect: {
      required: false,
      type: String
    },
  },
  data() {
    return {
      snackbar: false,
      showPw: false,
      valid: true,
      name: "",
      /*
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      */
      rules: {
        required: (value) => !!value || "Dieses Feld darf nicht leer sein",
        min: (v) => (v && v.length >= 6) || "Min 6 characters",
        emailMatch: () => "The email and password you entered don't match",
      },
      user: new User("", ""),
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/location");
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    handleLogin() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.loading = false;
            if(this.redirect) {
              //console.log(this.redirect)
              this.$router.push(this.redirect);
            } else {
              this.$router.push("/location");
            }
            
          },
          (error) => {
            this.loading = false;
            var errorMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            EventBus.$emit("snackbar", errorMessage);
          }
        );
      }
    },
  },
};
</script>