import axios from "axios";
import authHeader from './services/auth-header';
import store from './store';
import router from './router'

//const API_URL= process.env.VUE_APP_API_URL || "https://api.nett.media/api"
const API_URL= process.env.VUE_APP_API_URL

const http = axios.create({
	baseURL: API_URL,
	//timeout: 1000,
	headers: { 'Content-Type': 'application/json' },
});

http.interceptors.request.use(
	function (config) {
		const token = authHeader();
		if (token) {
			config.headers = { ...config.headers, ...token }
			//console.log("logged in: " + JSON.stringify(config.headers))
		} else {
			//console.log("not logged in" + JSON.stringify(config.headers))
		}
		return config;
	},
	function (error) {
		console.log("MyAxiosError: " + error)
		return Promise.reject(error);
	}
);


http.interceptors.response.use(
	function (config) {
		//console.log("RX config: ");
		//console.log(config);
		return config;
	},
	function (error) {
		console.log("General Response Error: ");
		//console.log(error);
		if (error.response) {
			/*
			 * The request was made and the server responded with a
			 * status code that falls out of the range of 2xx
			 */
			console.log(error.response.status);
			console.log(error.response.data);
			console.log(error.response.headers);
			//console.log(error.response);

			if (error.response.status == 403) {
				//router.push({ name: 'login' });
				return Promise.reject(error.response.data)
				//return 0;
			} else if (error.response.status == 401) {
				store.dispatch('auth/logout');
				if(error.response.config.url != 'auth/signin') { // only go to login page if we are not there already..
					//console.log(error.response.config.url)
					router.push({ name: 'login', params: { redirect: error.response.config.url } }); 
				}
				return Promise.reject(error.response.data)
			} else {
				return Promise.reject(error)
			}

		} else if (error.request) {
			/*
			 * The request was made but no response was received, `error.request`
			 * is an instance of XMLHttpRequest in the browser and an instance
			 * of http.ClientRequest in Node.js
			 */
			console.log("Error Request Intercept")
			console.log(error.request);
			return Promise.reject(error);
		} else {
			// Something happened in setting up the request and triggered an Error
			console.log('Something happened in setting up the request and triggered an Error', error.message);
			console.log(error.config);
			return Promise.reject(error)
		}
	}
)


export default http;
