<template>
  <div align="center">
    <v-img
      contain
      src="@/assets/Home.jpg"
    >
    </v-img>
    <v-btn 
        fixed
        bottom
        x-large
        :to="{ name: 'login' }"
        color="accent">
      Login
    </v-btn>
  </div>
</template>

<script>
//import UserService from "../services/user.service";

export default {
  name: "App-Home",
  data() {
    return {
      content: "",
    };
  },
  /*
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    
  },*/
};
</script>
