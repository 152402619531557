<template>
  <v-snackbar v-model="snackbar" :timeout="2000">
    {{ this.message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="accent" text v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { EventBus } from "../event-bus.js";

export default {
  name: "snackbar",
  data() {
    return {
      snackbar: false,
      message: null,
    };
  },
  mounted() {
    // adding eventHub listener
    EventBus.$on('snackbar', (payload) => {
      //console.log('Custom event triggered!')
      this.message = payload;
      this.snackbar = true;
    })
  },
  beforeDestroy() {
    // removing eventHub listener
    EventBus.$off('snackbar')
  }
}
</script>

<style>

</style>