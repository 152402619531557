<template>
  <v-app id="Journal Tracker" :dark="setTheme()">
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-avatar 
        class="ma-6"
        color="secondary"
        size="56"
      >
        <span class="white--text headline">{{ intials }}</span>
      </v-avatar>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ displayUser.username }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <p v-for="(role, index) in displayUser.roles" :key="index">
              {{ role }}
            </p>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      
      <v-list nav>
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.link" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-switch label="Dunkles Theme" v-model="goDark"></v-switch>
        </v-list-item>
        <v-list-item>
          <v-switch label="Tabellen" v-model="goTable" @click="setTable()"></v-switch>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <!--
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
    -->
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-toolbar-title>Lager Verwaltung net.Media</v-toolbar-title>

      <v-spacer></v-spacer>
      <!--
      <v-btn v-if="currentUser" icon :to="{ name: 'profile' }">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      -->
      <v-btn v-if="currentUser" icon @click="logOut">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <!--
      <v-btn v-if="!currentUser" icon :to="{ name: 'register' }">
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
      -->
      <v-btn v-if="!currentUser" icon :to="{ name: 'login' }">
        <v-icon>mdi-login</v-icon>
      </v-btn>

      <!--
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
      -->
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <Snackbar/>
  </v-app>
</template>

<script>

import Snackbar from "@/components/Snackbar";

export default {
  name: "App",
  components: {
    Snackbar
  },

  data: () => ({
    drawer: null,
    goDark: false,
    goTable: false,
    items: [
      {
        icon: "mdi-home",
        text: "Start",
        link: "/home",
      },
      {
        icon: "mdi-map-marker",
        text: "Location",
        link: "/location",
      },
      {
        icon: "mdi-archive",
        text: "Boxes",
        link: "/box",
      },
      {
        icon: "mdi-barcode",
        text: "Items",
        link: "/item",
      },
      {
        icon: "mdi-account",
        text: "Benutzer",
        link: "/user",
      },
      {
        icon: "mdi-help",
        text: "Hilfe",
        link: "/help",
      },
    ],
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    displayUser() {
      if(this.currentUser) return this.currentUser
      else return { username:"Allgemein", roles: ["öffentlich"]}
    }, 
    intials() {
      return this.displayUser.username.charAt(0).toUpperCase();
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("Admin");
      }
      return false;
    },
    isOwner() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("Eigentümer");
      }
      return false;
    },
    isWorker() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("Bereichsleiter");
      }
      return false;
    },
  },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    setTable() {
      this.$store.dispatch("table/switch");
      /*
      if(this.goTable == true) {
        
        this.$useTable = true;
        //Vue.prototype.$useTable = true
        //return true
      } else {
        this.$useTable = false;
        //Vue.prototype.$useTable = false
        //return false
      }
      console.log("useTable:" + this.$useTable)
      */
    },
    setTheme() {
      if (this.goDark == true) {
        this.$vuetify.theme.dark = true;
        return true;
      } else {
        this.$vuetify.theme.dark = false;
        return false;
      }
    },
  },
};
</script>
